@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .card {
    @apply bg-white shadow-lg rounded w-[90%] h-[90vh] flex flex-col;
  }

  @layer utilities {
    .scrollbar {
      scrollbar-color: #ddd transparent;
      scrollbar-width: thin;
    }

    .scrollbar::-webkit-scrollbar {
      width: 8px;
    }

    .scrollbar::-webkit-scrollbar-thumb {
      background: #ddd;
      border: 3px solid #ddd;
    }
  }
}
